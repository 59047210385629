import { keysToCamelCaseDeep } from '@agro1desenvolvimento/utils-js';
import { APIBase, loginService } from '@agro1desenvolvimento/apis-js-package';
import { ENV } from './constants';

class ServicesLoginApi extends APIBase {
  constructor() {
    super();

    let baseURL = 'http://localhost:3000';

    if (ENV === 'production') baseURL = 'https://login.aqila.com.br/api';
    else if (ENV === 'homologation') baseURL = 'https://login-homologacao.aqila.com.br/api';

    this.initialize({ baseURL });

    this.request.interceptors.response.use((response) => {
      const contentType = response.headers['content-type'];
      if (contentType?.includes('json')) {
        response.data = keysToCamelCaseDeep(response.data);
      }
      return response;
    });

    this.request.interceptors.request.use((request) => {
      request.headers = {
        ...loginService.requestHeaders(),
        ...request.headers,
      };

      return request;
    });
  }
}

export default new ServicesLoginApi();
