import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ReactNotification from 'react-notifications-component';
import { Switch, Route, useHistory } from 'react-router-dom/';
import 'animate.css/animate.compat.css';
import { Button, Menu, Menubar } from '@agro1desenvolvimento/react-components';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import Loader from './components/Loader';
import LoginPage from './pages/LoginPage';
import SendEmailPage from './pages/ResetPasswordPages/SendEmailPage';
import {
  UsersPages, AppsPage, ChangePasswordPage, ConfirmationPage,
} from './pages';
import { InternalServerError, NotFound } from './pages/ErrorPages';
import { RouteType } from './@types/route';
import { useCurrentUser } from './hooks';
import { ENV } from './services/constants';
import loaderService from './services/loader-service';

const routes: RouteType[] = [
  {
    exact: true,
    label: 'APPs',
    path: ['/apps', '/'],
    rootPath: '/apps',
    component: AppsPage,
  },
  {
    exact: true,
    roleAction: 'services_login.users',
    label: 'Usuários',
    path: [
      '/usuarios',
      '/usuarios/:action(novo)',
      '/usuarios/:action(editar|nova-permissao)/:id',
      '/usuarios/:id',
    ],
    rootPath: '/usuarios',
    component: UsersPages,
  },
];

const publicRoutes: RouteType[] = [
  {
    path: ['/reset_password/confirmation'],
    rootPath: '/reset_password/confirmation',
    component: ConfirmationPage,
    exact: true,
  },
  {
    path: ['/login'],
    rootPath: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: ['/send_email'],
    rootPath: '/send_email',
    component: SendEmailPage,
    exact: true,
  },
  {
    path: ['/reset_password/:id/:token/:app', '/reset_password/:id/:token'],
    rootPath: '/reset_password',
    component: ChangePasswordPage,
    exact: false,
  },
];

const goToLogin = (history: ReturnType<typeof useHistory>) => history.push('/login');

const logout = async (history: ReturnType<typeof useHistory>) => {
  goToLogin(history);
  await loginService.setToken(null);
};

const NavBar: React.FC<{ allowedRoutes: RouteType[] }> = ({ allowedRoutes }) => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const menuRef = useRef<Menu>(null);

  const onTabChange: onTabChangeType = ({ item, originalEvent }) => {
    originalEvent.preventDefault();
    const route = allowedRoutes.find(({ rootPath }) => rootPath === item.url);
    if (route) history.push(route.rootPath);
  };

  const navBarItens = useMemo(() => (
    allowedRoutes
      .map(({ label, rootPath }) => (label ? { label, url: rootPath, command: onTabChange } : null))
      .filter(Boolean) as { label: string, url: string }[]
  ), [allowedRoutes]);

  if (!currentUser) return null;

  const MenuBarEnd = () => {
    const items = [
      { label: currentUser.name, disabled: true },
      { separator: true },
      { label: 'Sair', command: () => logout(history) },
    ];

    return (
      <>
        <Menu model={items} popup ref={menuRef} id="popup_menu" />
        <Button className="p-button p-component p-button-text" onClick={(event) => menuRef.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup>
          <img height={32} className="avatar-usuario p-mr-1" src={currentUser.avatar} alt="Avatar usuário" />
        </Button>
      </>
    );
  };
  return (
    <Menubar
      model={navBarItens}
      end={MenuBarEnd}
      className="p-p-0"
    />
  );
};

const Routes = () => (
  <Switch>
    {publicRoutes.map(({ component, path, exact }) => (
      <Route path={path} exact={exact} component={component} key={path.join()} />
    ))}
    {routes.map(({ component, path, exact }) => (
      <Route path={path} exact={exact} component={component} key={path.join()} />
    ))}
    <Route path="/500" exact component={InternalServerError} />
    <Route component={NotFound} />
  </Switch>
);

const App: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [allowedRoutes, setRotasPermitidas] = useState<RouteType[]>([]);
  const [loaderVisible, setLoaderVisible] = useState(loaderService.visible);
  const history = useHistory();

  const inPublicRoute = useMemo(() => {
    const currentLocationPath = history.location.pathname;

    return publicRoutes.some((publicRoute) => currentLocationPath.includes(publicRoute.rootPath));
  }, [history.location.pathname]);

  const onCurrentUserChange = async () => {
    setRotasPermitidas(routes.filter(
      ({ roleAction }) => !roleAction || loginService.validateAccess(roleAction),
    ));

    if (!loginService.isLoggedIn && !inPublicRoute) goToLogin(history);
  };

  useEffect(() => {
    loginService.addOnUserChange(onCurrentUserChange);

    return () => loginService.removeOnUserChange(onCurrentUserChange);
  }, []);

  const initializer = async () => {
    const config = { extractTokenFromUrl: false, extractScopeFromUrl: false };

    loaderService.initialize((v) => { setLoaderVisible(v); });
    try {
      if (ENV === 'development') {
        await loginService.initializer({
          system: 'services_login',
          backendURL: 'http://localhost:3000',
          frontendURL: 'http://localhost:3000',
          ...config,
        });
      } else {
        await loginService.initializeByEnvironment('services_login', ENV, config);
      }
    } catch (error) {
      if (error.response?.status === 401) logout(history);
      else history.push('/500');

      console.error(error);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => { initializer(); }, []);

  return (
    <div>
      <ReactNotification isMobile={false} />
      {loaded && (
        <>
          <NavBar allowedRoutes={allowedRoutes} />
          <Routes />
        </>
      )}
      <Loader visible={loaderVisible} />
    </div>
  );
};

type onTabChangeType = (param: {
  item: { url: string, label: string },
  originalEvent: Event,
}) => void;

export default App;
