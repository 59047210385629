import { FC } from 'react';

const InternalServerError: FC = () => (
  <div className="p-mt-6 error-page">
    <h1 className="p-text-center error-code p-mb-0">500</h1>
    <p className="p-text-center error-message">
      Estamos passando por instabilidades,&nbsp;
      <br className="hidden-lg hidden-md" />
      tente novamente mais tarde.
    </p>
  </div>
);

export default InternalServerError;
