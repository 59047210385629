import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { useEffect, useState } from 'react';

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(loginService.currentUser);

  useEffect(() => {
    loginService.addOnUserChange(setCurrentUser);
    return () => loginService.removeOnUserChange(setCurrentUser);
  }, []);

  return currentUser;
};

export default useCurrentUser;
