import { User } from '@agro1desenvolvimento/apis-js-package';
import loader from '../decorators/loader';
import servicesLoginApi from './services-login-api';

class ResetPasswordService {
  readonly endpoint = '/reset_password'

  @loader
  async sendEmail(email: string) {
    const { data } = await servicesLoginApi.post(
      `${this.endpoint}/send_mail`,
      { email },
    );

    return data;
  }

  @loader
  async changePassword(id: string, token: string, password: string, app?: boolean) {
    const { data } = await servicesLoginApi.post(
      `${this.endpoint}/change_password`,
      {
        id, token, password, app,
      },
    );

    return data;
  }

  @loader
  async getUser(id: string, token: string) {
    const response = await servicesLoginApi.get<User>(this.endpoint, { params: { id, token } });

    return response.data;
  }
}

export default new ResetPasswordService();
