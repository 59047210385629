import React, { useMemo, useState } from 'react';
import {
  Button, Column, DataTable, SearchInput,
} from '@agro1desenvolvimento/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { User } from '@agro1desenvolvimento/apis-js-package';
import notifications from '../../services/notifications';
import UsersService from '../../services/users';
import UserModalForm from './UserModalForm';

const TableHeader: React.FC<{goToNewUser: () => void}> = ({ goToNewUser }) => (
  <div className="p-d-flex">
    <span className="p-my-auto">Usuários</span>
    <Button label="Novo" className="p-ml-auto" onClick={goToNewUser} />
  </div>
);

const ListUsers: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = async (query = '') => {
    try {
      setUsers(await UsersService.getUsers(query));
    } catch (error) {
      notifications.error({ message: 'Não foi possível carregar os usuários.' });
    }
  };

  const usersSystems = useMemo(() => (
    users.reduce<Record<string, string>>((acc, user) => {
      acc[user.id] = Array.from(new Set(user.roles.map(({ system }) => system))).sort().join(', ');
      return acc;
    }, {})
  ), [users]);

  const goToUserEdit = (userId: string) => history.push(`/usuarios/${userId}`);
  const goToNewUser = () => history.push('/usuarios/novo');

  return (
    <div id="list-users" className="p-mx-lg-3 p-mx-md-2 p-mx-2">
      <UserModalForm onClosed={(usersChanged) => { if (usersChanged) fetchUsers(); }} />
      <SearchInput
        searchIcon={<i><FontAwesomeIcon icon="search" /></i>}
        onPressEnter={fetchUsers}
      />
      <DataTable
        value={users}
        header={<TableHeader goToNewUser={goToNewUser} />}
        className="p-datatable-striped p-datatable-hoverable-rows"
      >
        <Column className="column-type" header="Tipo" body={({ type }: User) => (<FontAwesomeIcon title={type} icon={type === 'app' ? 'desktop' : 'user'} />)} />
        <Column className="column-name" field="name" header="Login" />
        <Column className="hidden-xs column-uuid" header="UUID" field="uuid" />
        <Column className="hidden-md hidden-sm hidden-xs column-email" header="E-Mail" field="email" />
        <Column className="hidden-sm hidden-xs column-cpf" header="CPF" field="cpfFormatted" />
        <Column
          className="hidden-md hidden-sm hidden-xs"
          header="Sistemas"
          body={({ id }: User) => (
            <p
              className="p-text-nowrap p-text-truncate p-m-0"
              title={usersSystems[id]}
            >
              {usersSystems[id]}
            </p>
          )}
        />
        <Column
          className="view p-p-0"
          body={({ id }: User) => <FontAwesomeIcon icon="eye" className="icon" onClick={() => goToUserEdit(id)} />}
        />
      </DataTable>
    </div>
  );
};

export default ListUsers;
