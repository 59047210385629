import { Card, DataView } from '@agro1desenvolvimento/react-components';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { App } from '../../@types/app';
import AppsService from '../../services/apps';
import notifications from '../../services/notifications';

const AppsPage: React.FC<PropTypes> = () => {
  const [apps, setApps] = useState<App[]>([]);
  const [loading, setLoading] = useState(false);

  const onCardClick = async (app: App) => {
    if (loading) return;

    try {
      setLoading(true);
      window.location.href = await AppsService.externalAppUrl(app.internalUrl);
    } catch (error) {
      notifications.error({ message: 'Falha ao carregar URL da aplicação.' });
    } finally {
      setLoading(false);
    }
  };

  const carregaApps = async () => {
    setApps(
      (await AppsService.allAPPs()).sort(
        (appA, appB) => appA.description.localeCompare(appB.description),
      ),
    );
  };

  const itemTemplate = (app: App) => (
    <div className="p-col-12 p-md-6 p-lg-3 p-p-2 apps-card">
      <div
        onClick={() => onCardClick(app)}
        role="button"
        tabIndex={0}
      >
        <Card
          title={app.description}
          className="ui-card-shadow p-text-center full-height"
          header={<img src={app.avatarUrl} alt="APP logo" className="p-pt-2" />}
        />
      </div>
    </div>
  );

  useEffect(() => {
    carregaApps();
  }, []);

  return (
    <div className="apps-page p-justify-center p-mx-lg-3">
      <DataView emptyMessage="Nenhuma aplicação disponível" value={apps} itemTemplate={itemTemplate} />
    </div>
  );
};

type PropTypes = RouteComponentProps<Record<string, never>>;

export default AppsPage;
