import servicesLoginApi from './services-login-api';
import loader from '../decorators/loader';

class RoleActionsService {
  readonly endpoint = '/role_actions'

  @loader
  async getRoles() {
    return (await servicesLoginApi.get<string[]>(this.endpoint)).data;
  }
}

export interface UserData {
  email: string;
  name: string;
  password: string;
  cpf: string;
}

export default new RoleActionsService();
