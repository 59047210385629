import {
  ColumnGroup, Row, Column, InputText, MultiSelect,
} from '@agro1desenvolvimento/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserRouteParams } from '../../@types/route-params';
import notificationsService from '../../services/notifications';
import usersService from '../../services/users';
import roleActionsService from '../../services/role-action';

const RoleCreationRow: React.FC<
  { onCreated: () => void | Promise<void> }
> = ({ onCreated }) => {
  const history = useHistory();
  const { action, id } = useParams<UserRouteParams>();
  const [submitted, setSubmitted] = useState(false);
  const [actions, setActions] = useState<string[]>([]);
  const [scope, setScope] = useState('');
  const [actionSuggestions, setActionSuggestions] = useState<Record<'value', string>[]>([]);

  const loadActions = async () => {
    try {
      const newRoles = await roleActionsService.getRoles();

      // Necessário fazer isso já que as opções tem que ser objetos
      setActionSuggestions(newRoles.map((value) => ({ value })));
    } catch (error) {
      notificationsService.error({ message: 'Falha ao carregar ações.' });
    }
  };

  const goToShowUser = () => history.push(`/usuarios/${id}`);

  const inputsValid = () => !!actions && !!scope;

  const onSubmit = async () => {
    if (submitted || !inputsValid()) return;

    try {
      setSubmitted(true);
      await usersService.createRole(id || '', { actions, scope });
      notificationsService.success({ message: 'Permissão criada com sucesso.' });
      onCreated();
      goToShowUser();
    } catch (error) {
      notificationsService.error({ message: 'Falha ao criar a permissão.' });
    } finally {
      setSubmitted(false);
    }
  };

  useEffect(() => {
    if (action === 'nova-permissao') {
      setScope('');
      setActions([]);
    }
  }, [action]);

  useEffect(() => {
    loadActions();
  }, []);

  if (action !== 'nova-permissao') return null;

  return (
    <ColumnGroup>
      <Row>
        <Column header="Ação" />
        <Column header="Escopo" className="escopo" />
        <Column headerClassName="actions edition" />
      </Row>
      <Row>
        <Column
          header={(
            <MultiSelect
              value={actions}
              onChange={({ value }) => setActions(value)}
              options={actionSuggestions}
              filterPlaceholder="Filtrar"
              optionLabel="value"
              placeholder="Ações*"
              filter
              emptyFilterMessage="Nenhum registro foi encontrado"
              selectedItemsLabel="{0} ações selecionadas"
            />
          )}
        />
        <Column
          header={(
            <InputText
              value={scope}
              onChange={(e) => setScope(e.currentTarget.value)}
              placeholder="Escopo*"
            />
        )}
        />
        <Column
          headerClassName="actions edition"
          header={(
            <>
              <FontAwesomeIcon icon="times" className="p-error" onClick={goToShowUser} />
              <FontAwesomeIcon icon="check" className="confim p-ml-3" onClick={onSubmit} />
            </>
          )}
        />
      </Row>
    </ColumnGroup>
  );
};

export default RoleCreationRow;
