import { ReactNotificationOptions, store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class NotificationsService {
    notificationContent: ReactNotificationOptions = {
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    };

    success = (params: Partial<ReactNotificationOptions>) => {
      store.addNotification({
        ...this.notificationContent,
        ...params,
        type: 'success',
      });
    }

    error = (params: Partial<ReactNotificationOptions>) => {
      store.addNotification({
        ...this.notificationContent,
        ...params,
        type: 'danger',
        title: 'Erro',
      });
    }
}

export default new NotificationsService();
