import React from 'react';
import { Button, Dialog } from '@agro1desenvolvimento/react-components';

const DialogConfirmation: React.FC<PropsType> = ({
  children, visible, title, confirmation, rejection, onHide = () => null,
}) => {
  const DialogFooter = () => (
    <div>
      {rejection && <Button className="p-button p-component p-button-text" label={rejection.label} onClick={rejection.onClick} />}
      {confirmation && <Button className="p-button p-component" label={confirmation.label} onClick={confirmation.onClick} />}
    </div>
  );

  return (
    <Dialog
      header={title}
      visible={visible}
      onHide={onHide}
      footer={<DialogFooter />}
    >
      {children}
    </Dialog>
  );
};

interface DialogActionType {
  label?: string,
  onClick?: () => void,
}

interface PropsType {
  title: string,
  visible: boolean,
  confirmation?: DialogActionType,
  rejection?: DialogActionType,
  onHide?: () => void,
}

export default DialogConfirmation;
