import React, { useState, useEffect } from 'react';
import {
  InputText,
  Button,
  Dialog,
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import { useHistory, useParams } from 'react-router-dom';
import { User } from '@agro1desenvolvimento/apis-js-package';
import UsersService from '../../services/users';
import NotificationsServices from '../../services/notifications';
import { UserRouteParams } from '../../@types/route-params';

const UserModalForm: React.FC<PropsType> = ({ onClosed = () => null, user }) => {
  const history = useHistory();
  const { action } = useParams<UserRouteParams>();
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [type, setType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [userChanged, setUserChanged] = useState(false);

  const inputs: inputType[] = [
    {
      autoFocus: true,
      label: 'Login',
      value: name,
      onTextChange: setName,
      required: true,
    },
    {
      label: 'Tipo',
      value: type,
      onTextChange: setType,
      required: true,
      type: 'select',
      items: ['app', 'person'],
      disabled: () => action === 'editar',
    },
    {
      label: 'CPF',
      value: cpf,
      onTextChange: setCpf,
      required: () => type === 'person',
      disabled: () => type !== 'person',
    },
    {
      label: 'E-mail',
      value: email,
      onTextChange: setEmail,
      type: 'email',
      required: () => type === 'person',
      disabled: () => type !== 'person',
    },
    {
      label: 'Senha',
      value: password,
      onTextChange: setPassword,
      required: !user,
      type: 'password',
    },
    {
      id: 'confirmacaoSenha',
      label: 'Confirme a senha',
      value: passwordConfirmation,
      onTextChange: setPasswordConfirmation,
      required: !user,
      type: 'password',
    },
  ];

  const closeModal = () => {
    history.push(user ? `/usuarios/${user.id}` : '/usuarios');
    setName('');
    setCpf('');
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setType('');
  };

  const validadeInputs = () => {
    if (password !== passwordConfirmation) {
      NotificationsServices.error({ message: 'Senha e a confirmação da senha não coincidem' });
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (!validadeInputs()) return;

    const userObject = {
      name,
      cpf,
      email,
      password,
      type,
    };

    try {
      if (!user && userObject.type === 'person' && (await UsersService.getUsers(userObject.cpf)).length !== 0) {
        NotificationsServices.error({ message: 'Já existe um cliente com esse CPF cadastrado.' });
        return;
      }

      if (user) await UsersService.edit(user.id, userObject);
      else await UsersService.createNewUser(userObject);

      NotificationsServices.success({ message: `Usuário ${user ? 'atualizado' : 'criado'} com sucesso` });
      setUserChanged(true);
      closeModal();
    } catch (error) {
      NotificationsServices.error({ message: `Falha ao ${user ? 'atualizar' : 'criar'} usuário` });
    }
  };

  useEffect(() => {
    if (action === 'editar' && user) {
      setEmail(user.email);
      setName(user.name);
      setCpf(user.cpf);
      setType(user.type);
    }
  }, [user, action]);

  const Footer = () => (
    <div className="p-d-flex p-justify-end">
      <Button
        className="p-button-raised p-button-text"
        onClick={closeModal}
        label="Cancelar"
        type="button"
      />
      <Button
        type="submit"
        label="Salvar"
        form="user-form"
      />
    </div>
  );

  const renderInput = ({
    type: inputType,
    required,
    disabled,
    id,
    value,
    onTextChange,
    items,
    autoFocus,
  }: inputType) => {
    if (inputType === 'select') {
      return (
        <Dropdown
          autoFocus={autoFocus}
          options={items?.map((item: string) => ({ label: item, value: item }))}
          filter={false}
          required={typeof required === 'function' ? required() : required}
          disabled={typeof disabled === 'function' ? disabled() : disabled}
          id={id}
          value={value}
          onChange={(e) => onTextChange(e.value)}
        />
      );
    }

    return (
      <InputText
        autoFocus={autoFocus}
        required={typeof required === 'function' ? required() : required}
        disabled={typeof disabled === 'function' ? disabled() : disabled}
        type={inputType}
        className="p-d-block"
        id={id}
        value={value}
        onChange={(e) => onTextChange(e.currentTarget.value)}
      />
    );
  };

  return (
    <Dialog
      visible={!!action && ['editar', 'novo'].includes(action)}
      onHide={() => onClosed(userChanged)}
      header={`${user ? 'Editar' : 'Criar'} usuário`}
      footer={<Footer />}
      className="user-modal-form"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        className="p-mt-1"
        id="user-form"
      >
        {inputs.map((input) => {
          const {
            label,
            required,
            id = label,
          } = input;
          return (
            <div className="p-field p-fluid ">
              <div className="p-float-label" key={id}>
                { renderInput(input) }
                <label htmlFor={id}>{label}{(typeof required === 'function' ? required() : required) && '*'}</label>
              </div>
              {(typeof required === 'function' ? required() : required) && <small className="p-d-block">*Obrigatório</small>}
            </div>
          );
        })}

      </form>
    </Dialog>
  );
};

type inputType = {
  value: string;
  onTextChange: (value: string) => void;
  label: string;
  required?: boolean | (() => boolean);
  id?: string;
  type?: string;
  disabled?: boolean | (() => boolean);
  items?: string[],
  autoFocus?: boolean,
}

type PropsType = {
  onClosed?: (userChanged: boolean) => void,
  user?: User,
}

export default UserModalForm;
