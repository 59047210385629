import React, { FC, useState } from 'react';
import { Button, Card, InputText } from '@agro1desenvolvimento/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router-dom';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import userService from '../../services/users';
import notifications from '../../services/notifications';

type PropTypes = RouteComponentProps;

const LoginPage: FC<PropTypes> = ({ history }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const footer = (
    <>
      <div className="p-text-center">

        <Button
          label="Entrar"
          className="login-action"
          type="submit"
          form="login-form"
          disabled={loading}
        />

        <div className="p-text-center p-my-5">
          { loading && <FontAwesomeIcon icon="spinner" spin /> }
        </div>

        <a href="#/send_email">Esqueci minha senha</a>
      </div>
    </>
  );

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      loginService.currentUser = await userService.login(login, password);
      history.push('/');
    } catch (error) {
      let errorMessage = 'Não foi possível realizar o login';

      if (error.response?.status === 401) errorMessage = 'Usuário e/ou Senha inválidos';

      notifications.error({ message: errorMessage });
      console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-d-flex p-jc-center login-page">
      <Card className="p-as-center p-p-5" footer={footer} title="Entrar">
        <form onSubmit={handleLogin} id="login-form">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <InputText
                id="login"
                autoFocus
                required
                value={login}
                title="Login"
                placeholder="Login"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
              />
            </div>

            <div className="p-col-12">
              <InputText
                id="password"
                type="password"
                required
                value={password}
                title="Password"
                placeholder="Senha"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
