import servicesLoginApi from './services-login-api';
import { App } from '../@types/app';
import loader from '../decorators/loader';

class AppsService {
  readonly endpoint = '/apps'

  @loader
  async allAPPs() {
    return (await servicesLoginApi.get<App[]>(this.endpoint)).data;
  }

  @loader
  async externalAppUrl(url: string) {
    return (await servicesLoginApi.get<string>(url)).data;
  }
}

export interface UserData {
  email: string;
  name: string;
  password: string;
  cpf: string;
}

export default new AppsService();
