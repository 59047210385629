import { User, Role } from '@agro1desenvolvimento/apis-js-package';
import loader from '../decorators/loader';
import servicesLoginApi from './services-login-api';

class UsersService {
  readonly endpoint = '/users'

  @loader
  async getUsers(query = '') {
    const response = await servicesLoginApi.get<User[]>(this.endpoint, { params: { q: query } });

    return response.data;
  }

  @loader
  async createNewUser(newUserData: UserData) {
    const userObject = {
      user: newUserData,
    };

    await servicesLoginApi.post(this.endpoint, userObject);
  }

  @loader
  async edit(userId: string, userData: UserData) {
    const data = {
      user: userData,
    };

    await servicesLoginApi.patch(`${this.endpoint}/${userId}`, data);
  }

  @loader
  async getUserById(userId: string) {
    const response = await servicesLoginApi.get<User>(this.endpoint, { params: { id: userId } });

    return response.data;
  }

  @loader
  async removeRole(ids: { user: string, role: string }) {
    await servicesLoginApi.delete(`${this.endpoint}/${ids.user}/roles/${ids.role}`);
  }

  @loader
  async createRole(userId: string, role: { actions: string[], scope: string }) {
    await servicesLoginApi.post<Role>(`${this.endpoint}/${userId}/roles`, { role });
  }

  @loader
  async login(login: string, password: string) {
    const { data } = await servicesLoginApi.post<User>(
      '/users/login',
      { login, password },
    );

    return data;
  }
}

export interface UserData {
  email: string;
  name: string;
  password: string;
  cpf: string;
}

export default new UsersService();
