class LoaderService {
  #loaderListener: (value: boolean) => void = () => [];

  #visible = false;

  private ids = new Set<string>([]);

  initialize(listener: (value: boolean) => void) {
    this.#loaderListener = listener;
  }

  get visible() { return this.#visible; }

  set visible(value: boolean) {
    if (value !== this.#visible) {
      this.#visible = value;
      this.#loaderListener(value);
    }
  }

  show = (id: string) => {
    this.visible = true;
    this.ids.add(id);
  };

  hide = (id: string) => {
    if (this.ids.size === 1) {
      this.ids.delete(id);
      this.visible = !!this.ids.size;
    } else {
      this.ids.delete(id);
    }
  };
}

const loaderService = new LoaderService();

export default loaderService;
