import { FC } from 'react';
import { Card } from '@agro1desenvolvimento/react-components';
import { RouteComponentProps } from 'react-router-dom';

type PropTypes = RouteComponentProps;

const ConfirmationPage: FC<PropTypes> = () => (
  <div className="p-d-flex p-jc-center login-page">
    <Card className="p-as-center p-p-5" title="Alteração de senha efetuada com sucesso!">
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-text-center">
          Volte ao aplicativo Aqila e realize o login.
        </div>
      </div>
    </Card>
  </div>
);

export default ConfirmationPage;
