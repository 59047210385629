import React, { FC, useState } from 'react';
import { Button, Card, InputText } from '@agro1desenvolvimento/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router-dom';
import ResetPasswordService from '../../services/reset-password';
import notificationsService from '../../services/notifications';

type PropTypes = RouteComponentProps;

const SendEmailPage: FC<PropTypes> = ({ history }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const footer = (
    <>
      <Button
        label="Enviar e-mail"
        className="login-action"
        type="submit"
        form="reset-password-form"
        disabled={loading}
      />

      <div className="p-text-center">
        { loading && <FontAwesomeIcon icon="spinner" spin /> }
      </div>
    </>
  );

  const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      await ResetPasswordService.sendEmail(email);
    } catch (errorMessage) {
      console.error(errorMessage);
    } finally {
      notificationsService.success({ message: 'Email de redefinição enviado com sucesso.' });
      history.push('/login');
      setLoading(false);
    }
  };

  return (
    <div className="p-d-flex p-jc-center login-page">
      <Card className="p-as-center p-p-5" footer={footer} title="Recuperação de senha">
        <form onSubmit={handleResetPassword} id="reset-password-form">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <InputText
                id="email"
                autoFocus
                required
                value={email}
                title="Email"
                placeholder="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default SendEmailPage;
