import {
  useEffect, useState, ComponentPropsWithRef, FC,
} from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Column, DataTable } from '@agro1desenvolvimento/react-components';
import { User, Role } from '@agro1desenvolvimento/apis-js-package';
import UsersService from '../../services/users';
import NotificationsServices from '../../services/notifications';
import { UserRouteParams } from '../../@types/route-params';
import UserModalForm from './UserModalForm';
import RoleCreationRow from './RoleCreationRow';
import DialogConfirmation from '../../components/DialogConfirmation';

const TableHeader: FC<TableHeaderPropsType> = ({ goToNewUser, disabled }) => (
  <div className="p-d-flex">
    <span className="p-my-auto">Permissões</span>
    <Button label="Nova" className="p-ml-auto" onClick={goToNewUser} disabled={disabled} />
  </div>
);

const defaultConfirmationProps: ComponentPropsWithRef<typeof DialogConfirmation> = {
  title: '',
  visible: false,
};

const ShowUser: FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id, action } = useParams<UserRouteParams>();
  const [user, setUser] = useState<User>();
  const [confirmationProps, setConfirmationProps] = useState(defaultConfirmationProps);

  const closeConfirmation = () => {
    setConfirmationProps((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const userTableRows = [
    {
      label: 'Login',
      value: user?.name,
    },
    {
      label: 'UUID',
      value: user?.uuid,
    },
    {
      label: 'Tipo',
      value: user?.type,
    },
    {
      label: 'E-mail',
      value: user?.email,
    },
  ];

  const fetchUser = async () => {
    try {
      setLoading(true);
      setUser(await UsersService.getUserById(id || ''));
    } catch (error) {
      NotificationsServices.error({ message: 'Falha ao buscar usuário' });
    } finally {
      setLoading(false);
    }
  };

  const removeRole = async (roleId: string) => {
    if (loading) return;

    closeConfirmation();

    try {
      setLoading(true);
      await UsersService.removeRole({
        role: roleId,
        user: user?.id || '',
      });

      setUser((prevUser) => {
        if (!prevUser) return prevUser;

        return {
          ...prevUser,
          roles: prevUser.roles.filter((role) => role.uuid !== roleId),
        };
      });
    } catch (error) {
      NotificationsServices.error({ message: 'Não foi possível remover a permissão' });
    } finally {
      setLoading(false);
    }
  };

  const showDeleteRoleConfirmation = (role: Role) => {
    setConfirmationProps({
      title: 'Confirmar exclusão',
      children: (
        <p>
          Realmente deseja excluir a permissão <b>{role.action} </b>
          do escopo <b>{role.scope}</b>?
        </p>
      ),
      visible: true,
      confirmation: {
        label: 'Sim',
        onClick: () => removeRole(role.uuid),
      },
      rejection: {
        label: 'Não',
        onClick: closeConfirmation,
      },
    });
  };

  useEffect(() => {
    if (id && action !== 'editar') fetchUser();
  }, [id]);

  return (
    <div className="show-user p-mx-lg-4 p-mx-md-3 p-mx-2">
      <UserModalForm
        user={user}
        onClosed={(userChanged) => { if (userChanged) fetchUser(); }}
      />
      <div>
        <div className="header p-grid p-m-0">
          <Link to="/usuarios" className="p-justify-start p-my-auto ">
            <Button>
              <FontAwesomeIcon icon="chevron-left" className="p-m-auto" />
              <span className="hidden-xs p-ml-2">Voltar</span>
            </Button>
          </Link>
          <h1 className="p-justify-center p-mx-3 p-my-auto p-col p-text-truncate">{user?.name}</h1>
          <Link to={`/usuarios/editar/${user?.id}`} className="p-justify-end p-my-auto">
            <Button>
              <FontAwesomeIcon icon="edit" className="p-m-auto" />
              <span className="hidden-xs  p-ml-2 p-align-center">Editar</span>
            </Button>
          </Link>
        </div>
      </div>
      {userTableRows.map(({ label, value }) => (
        <p key={label} title={value}><b>{label}: </b>{value}</p>
      ))}
      <DataTable
        value={user?.roles}
        className="p-datatable-striped p-datatable-hoverable-rows role-creation-row"
        header={(
          <TableHeader
            goToNewUser={() => history.push(`/usuarios/nova-permissao/${user?.id}`)}
            disabled={action === 'nova-permissao'}
          />
        )}
        headerColumnGroup={RoleCreationRow({ onCreated: fetchUser })}
      >
        <Column
          field="action"
          header="Ação"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Filtrar"
        />
        <Column
          field="scope"
          header="Escopo"
          className="escopo"
          filter
          filterMatchMode="equals"
          filterPlaceholder="Filtrar"
        />
        <Column
          className="actions"
          body={(role: Role) => (
            <div className="p-d-flex">
              <FontAwesomeIcon
                icon="trash-alt"
                className="icon-remover p-ml-auto"
                onClick={() => showDeleteRoleConfirmation(role)}
              />
            </div>
          )}
        />
      </DataTable>
      <DialogConfirmation {...confirmationProps} />
    </div>
  );
};
type TableHeaderPropsType = {
  goToNewUser: () => void,
  disabled: boolean,
};

export default ShowUser;
