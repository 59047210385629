/* eslint-disable no-param-reassign */
import { uniqueId } from 'lodash';
import loaderService from '../services/loader-service';

const loader = (
  _target: any,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<(...arg: any) => Promise<any>>,
) => {
  const originalMethod = descriptor.value;
  const id = uniqueId(propertyKey);

  if (!originalMethod) return;

  // eslint-disable-next-line func-names
  descriptor.value = async function (...args) {
    loaderService.show(id);

    const originalReturn = originalMethod.apply(this, args);

    originalReturn.finally(() => loaderService.hide(id));

    return originalReturn;
  };
};

export default loader;
