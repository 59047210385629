import { FC } from 'react';

const InternalServerError: FC = () => (
  <div className="p-mt-6 error-page">
    <h1 className="p-text-center error-code p-mb-0">404</h1>
    <p className="p-text-center error-message">
      Página não encontrada.
    </p>
  </div>
);

export default InternalServerError;
