import { library } from '@fortawesome/fontawesome-svg-core';
import * as solidSVGIcons from '@fortawesome/free-solid-svg-icons';

type iconsOnly<Icons extends Record<keyof any, any>> = SubType<
  Icons,
  Parameters<typeof library.add>[number]
>

const solidSVGIconsToAdd: (keyof iconsOnly<typeof solidSVGIcons>)[] = [
  'faEdit',
  'faChevronLeft',
  'faEye',
  'faTrashAlt',
  'faTimes',
  'faCheck',
  'faSearch',
  'faSignOutAlt',
  'faUser',
  'faDesktop',
  'faSpinner',
];

const solidIcons = solidSVGIconsToAdd.map((iconKey) => solidSVGIcons[iconKey]);

library.add(...solidIcons);
