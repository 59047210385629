import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ShowUser from './ShowUser';
import ListUsers from './ListUsers';
import { UserRouteParams } from '../../@types/route-params';

const UsersPages: React.FC<PropTypes> = ({ match }) => (
  match.params.id ? <ShowUser /> : <ListUsers />
);

type PropTypes = RouteComponentProps<UserRouteParams>;

export default UsersPages;
