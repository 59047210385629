import React from 'react';
import { ProgressSpinner, Dialog } from '@agro1desenvolvimento/react-components';

const Loader: React.FC<PropsType> = ({ visible, onHide = () => [], id }) => (
  <div className="loader-component">
    <Dialog
      id={id}
      onHide={onHide}
      visible={visible}
      showHeader={false}
      closable={false}
      contentClassName="p-content"
      modal
    >
      <div data-testid="loading-component">
        <ProgressSpinner className="progress-spinner" />
      </div>
    </Dialog>
  </div>
);

interface PropsType {
  visible: boolean,
  onHide?: () => void,
  id?: string,
}

export default Loader;
